<template>
  <div class="footer bg-clouducation-black text-white py-16 mdmax:py-10 relative overflow-hidden">
    <div class="absolute -left-24 z-0 flip" v-if="!isMobile">
      <Hexagon color="yellow" opacity="0.7" />
    </div>
    <div class="absolute -right-48 top-32 z-0 flip" v-if="!isMobile">
      <Hexagon color="clouducation-purple" opacity="0.7" />
    </div>
    <div class="container">
      <p class="text-4xl text-white font-bold text-center mdmax:text-3xl"><span class="text-yellow">CLOUD</span>UCATION</p>
      <p class="font-bold text-sm text-white text-center mb-6 mdmax:text-xs"><span class="text-yellow">SIMPLIFY TECHNOLOGY</span> FOR YOUR LIFE</p>
      <div class="flex justify-center items-center gap-8 mb-8">
        <a href="https://www.facebook.com/g2academygeeks" target="_blank" class="inline-block">
          <span class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
            <Facebook width="20" height="20" color="clouducation-black" />
          </span>
        </a>
        <a href="https://www.instagram.com/g2academyindonesia/" target="_blank" class="inline-block">
          <span class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
            <Instagram width="20" height="20" color="clouducation-black" />
          </span>
        </a>
        <a href="https://www.linkedin.com/company/g2lab" target="_blank" class="inline-block">
          <span class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
            <Linkedin width="20" height="20" color="clouducation-black" />
          </span>
        </a>
      </div>
      <div class="flex justify-center items-center gap-8">
        <div class="font-semibold text-xs py-1 cursor-pointer" @click="scrollTo('#hero')">HOME</div>
        <div class="font-semibold text-xs py-1 cursor-pointer" @click="scrollTo('#about-section')">ABOUT</div>
        <div class="font-semibold text-xs py-1 cursor-pointer" @click="scrollTo('#learnmore')">LEARN MORE</div>
      </div>
      <div class="text-center text-xs mt-4">Copyright © 2021 PT Generasi Teknologi Buana</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    Facebook: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Facebook'),
    Instagram: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Instagram'),
    Linkedin: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Linkedin'),
    Hexagon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Hexagon')
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  methods: {
    scrollTo(target) {
      this.$emit('scroll', target)
    }
  }
}
</script>
<style lang="scss" scoped>
.flip {
  transform: scale(-1);
  -webkit-transform: scale(-1);
}
</style>
